const UserActionTypes = {
  LOGIN: "user/login",
  LOGIN_SUCCESS: "user/login-success",
  LOGIN_ERROR: "user/login-error",
  LOGOUT: "user/logout",
  REGISTER_VERIFICATION: "register/verification",
  REGISTER_SUCCESS: "register/success"
};

export default UserActionTypes;

export const USER_ACTION_LOGIN_BEFORE = "user/login-before";
export const USER_ACTION_LOGIN = "user/login";
export const USER_ACTION_LOGIN_SUCCESS = "user/login-success";
export const USER_ACTION_REG_SUBMIT = "user/register-submit";
export const USER_ACTION_REG_SUBMIT_SUCCESS = "user/register-submit-success";
export const USER_ACTION_UPDATE_SUCCESS = "user/update-success";