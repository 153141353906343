import React, { useEffect, useState } from "react";
import styles from "./InternalLogin.module.scss";
import Form from "react-bootstrap/Form";
import { Link, useHistory } from "react-router-dom";
import withSimpleForm from "../../utils/SimpleForm";
import {  useDispatch, useSelector } from "react-redux";
import { beforeLogin, login, UserActionLoginError, UserActionLoginSuccess } from "../../store/user/actions";
import AlertDismissable from "../../components/AlertDismissable/AlertDismissable";
import { createValidator } from "../../utils/form";
import PasswordInput from "../../components/UI/PasswordInput/PasswordInput";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import classnames from "classnames";
import { v4 as uuid } from 'uuid'
import { ReactComponent as IconNewDevice } from "../../images/new-device.svg";
import {
  osName,
  browserName,
  engineName,
  deviceType,
} from "react-device-detect";

import axios from "axios";
import { axiosApi } from "../../utils/api";
import { APIInternal } from "../../utils/constant";
import { Alert } from "react-bootstrap";

const DEVICE_ID_KEY = 'app-name-device-id'

function useInterval(callback, delay) {
  const intervalRef = React.useRef();
  const callbackRef = React.useRef(callback);

  React.useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  React.useEffect(() => {
    if (typeof delay === 'number') {
      intervalRef.current = window.setInterval(() => callbackRef.current(), delay);

      return () => window.clearInterval(intervalRef.current);
    }
  }, [delay]);
  
  return intervalRef;
}


export const getDeviceId = () => {
    let deviceId = localStorage.getItem(DEVICE_ID_KEY) || ""
    if(!deviceId){
        deviceId = uuid()
        localStorage.setItem(DEVICE_ID_KEY, deviceId)
    }
    return deviceId
}

const GetIPAddress = async () => axios.get("https://api.ipify.org?format=json")
const GetAddressByIp = async () => {
  const { data: {ip} } = await GetIPAddress();
  return axios.get(`https://ipinfo.io/${ip}?token=10257beaea1314`)
}

// @withTopBarContext({ isFullscreen: true })
// @withRouter
// @withSimpleForm({
//   entries: props => ({
//     email: new URLSearchParams(props.location.search).get("email") || "",
//     password: "",
//     isAdmin: true
//   })
// })

// @withToast
const InternalLogin = (props) => {

  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector((state) => state.user);

  const [payload, setPayload] = useState({
    email: "", 
    password: "",
  })

  const [isNewDevice, setIsNewDevice] = useState(false);
  const [count, setCount] = useState(0);

  const onChange = (key, value) => {
    setPayload({
      ...payload,
      [key]: value
    })
  }

  const doLogin = async(e) => {
    if (e) e.preventDefault();
    // if (!_validator.allValid()) {
    //   _validator.showMessages();
    //   return;
    // }
    setCount(0)
    const agentInfor = await GetAddressByIp();
    const res = await axiosApi
      .auth()
      .post(
        APIInternal.LOGIN,
        {
          ...payload,
          ts: (new Date()).getTime(),
          osName,
          browserName,
          deviceType,
          deviceId: getDeviceId(),
          agentInfor: agentInfor?.data
        }
      );
      console.log(res)
    const { data, status } = res;

    if (status !== 200) {
      return dispatch(UserActionLoginError(data))
    }
    if (data?.accept === true) {
      dispatch(UserActionLoginSuccess({
          authentication: { ...payload },
          obj: data?.authentication
      }))
    } else {
      setIsNewDevice(true)
    } 
  };

  const verify = async () => {
    const res = await axiosApi
      .auth()
      .post(
        APIInternal.VERIFY_NEW_DEVICE,
        {
          token: getDeviceId()
        }
      );
    const { data, status } = res;

    if (status === 200 && data?.success === true) {
      dispatch(UserActionLoginSuccess({
          authentication: { ...payload },
          obj: data?.authentication
      }))
    } 
  }
  
  const intervalRef = useInterval(() => {
      verify();
      setCount(count + 1);
  }, isNewDevice ? 2000 : null);

  useEffect(() => {
    return () => {
      window.clearInterval(intervalRef.current);
    }
  }, [])

  useEffect(() => {
    if (user.status === "login-success") {
      return history.replace(history.location.state?.from?.pathname || "/analytics/dashboard")
    }
  }, [user])

  useEffect(() => {
    console.log(count)
  }, [count])

    return (
      <div>
        <div
          className="mx-auto mt-5"
          style={{ backgroundColor: "white !important" }}
        >
          <Container>
            <Grid container  spacing={0}>
              <Grid item md={2}>
              </Grid>
              {!isNewDevice && <Grid item md={8} xs={12}>
                <Form
                  onSubmit={doLogin}
                  // style={{ padding: "5rem", paddingBottom: "0" }}
                >
                  <Form.Group>
                    <div style={{ textAlign: "center" }} className="py-5">
                      <svg width="78" height="80" viewBox="0 0 78 80" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path opacity="0.986" fill-rule="evenodd" clip-rule="evenodd" d="M37.3641 0.113658C47.1591 -0.60429 55.8256 2.07907 63.3662 8.16374C71.9856 15.8489 75.5933 25.41 74.1904 36.8459C73.4756 41.088 71.9639 45.0236 69.6551 48.6527C72.4222 53.2561 75.2039 57.8476 78 62.4272C77.9686 62.5775 77.8875 62.6968 77.7581 62.785C75.6211 64.0373 73.4853 65.2895 71.3483 66.5417C68.95 62.686 66.5506 58.8292 64.1523 54.9735C61.3139 57.5161 57.988 59.0856 54.1748 59.6842C44.4597 59.7642 34.7433 59.7642 25.0282 59.6842C20.3406 58.7194 17.3775 55.9562 16.1391 51.3956C15.2792 39.9443 18.7067 29.9669 26.419 21.4613C29.3458 18.4559 32.7115 16.0504 36.5175 14.246C42.7907 11.8692 47.8097 13.4005 51.5746 18.8375C55.9478 26.1911 60.3826 33.5066 64.878 40.7815C67.6947 33.6235 67.171 26.7064 63.3058 20.0302C58.9144 13.3993 52.7271 9.4434 44.7415 8.16374C33.8714 6.91866 24.5795 10.0791 16.8647 17.6449C8.73031 26.5561 6.15005 36.8518 9.12458 48.5334C12.0489 57.5769 17.7936 64.2364 26.3586 68.5095C34.6901 72.2018 43.1559 72.5202 51.756 69.4636C52.9944 68.9722 54.2038 68.4153 55.3842 67.794C55.5221 67.7045 55.643 67.7248 55.747 67.8536C56.9056 70.0194 58.0945 72.166 59.3147 74.2936C59.3897 74.4069 59.3692 74.5059 59.2543 74.5918C49.7822 79.466 39.8651 80.5799 29.503 77.9311C16.3858 74.0205 7.33599 65.7713 2.35193 53.1846C-1.55964 41.7105 -0.592117 30.6586 5.25449 20.0302C11.5144 9.69027 20.6453 3.26929 32.6474 0.769591C34.2305 0.500062 35.8028 0.281816 37.3641 0.113658ZM40.7504 20.9842C40.8714 20.9807 40.9512 21.0403 40.9923 21.1631C39.1637 32.9854 33.4989 42.5465 24.0002 49.8453C23.4826 40.2746 26.6875 32.1446 33.615 25.4565C35.7266 23.5412 38.1055 22.0504 40.7504 20.9842ZM47.644 27.7821C51.6604 34.4297 55.7119 41.0677 59.7985 47.6986C59.917 47.8488 59.9581 48.0075 59.9194 48.1756C58.1247 50.0253 55.9683 51.2776 53.4491 51.9323C46.8374 52.0122 40.2268 52.0122 33.615 51.9323C40.5835 45.2096 45.2591 37.1595 47.644 27.7821Z" fill="#FB6719"/>
                      </svg>

                      <h2 className={"my-2"} style={{ fontWeight: "600" }}>
                        {"Log in to Qooee"}
                      </h2>

                    </div>
                    {user.status === "login-error" && (
                      <AlertDismissable
                        heading={user.error?.title}
                        variant={"danger"}
                      >
                        {'Invalid Emaill Address / Password.'}
                      </AlertDismissable>
                    )}
                  </Form.Group>
                  <Form.Group>
                    <TextField
                      className={"w-100"}
                      id={"email"}
                      type={"email"}
                      placeholder={"Email Address"}
                      label="Email Address"
                      variant="outlined"
                      onChange={e => onChange("email", e.target.value)}
                    />
                  </Form.Group>
                  <PasswordInput
                    id={"password"}
                    type={"password"}
                    name={"password"}
                    placeholder={"Password"}
                    size={"lg"}
                    onChange={e => onChange("password", e.target.value)}
                  >
                  </PasswordInput>
                  <Form.Group>
                    <Form.Text
                      className={classnames(styles.FormText, "mt-4 mb-4")}
                    >
                      <Link to={"/login/forgot"} className={"no-link"}>
                        <h6 className={"href-link"}>
                          <u>Forgot password?</u>
                        </h6>
                      </Link>
                    </Form.Text>
                  </Form.Group>
                  <Form.Group>
                    <div className="d-flex justify-content-end">
                      <Button
                        id={"submit"}
                        variant={"primary"}
                        size={"lg"}
                        className={"align-self-end w-100"}
                        style={{ paddingLeft: "3rem", paddingRight: "3rem" }}
                        type={"submit"}
                        onClick={doLogin}
                      >
                        Log in
                      </Button>
                    </div>
                  </Form.Group>
                </Form>
              </Grid>}
              {isNewDevice && <Grid item md={8} xs={12}>
                <div style={{ textAlign: "center" }} className="py-5">
                   <IconNewDevice />
                   <h3 className="mt-3">We've sent a notification to your email</h3>
                   <h3 style={{ fontWeight: "400" }}>Accept it to authenticate your login</h3>
                   {count > 5 && <Alert className="mt-5 p-3" variant="warning">
                    Haven’t received a push notification yet? Try to resend the notification.
                    <button onClick={doLogin} className="btn btn-outline mt-3 "><strong>Resend notification</strong></button>
                   </Alert>}
                </div>
              </Grid>}
            </Grid>
          </Container>
        </div>
      </div>
    );
}

export default InternalLogin;
