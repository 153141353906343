import React, { useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Sidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import { Link } from 'react-router-dom';
import { logout } from "../../store/user/actions";
import { FaCalendarAlt, FaUserFriends , FaChartBar, FaShoppingCart, FaGamepad, FaCogs, FaBars, FaArrowAltCircleRight } from "react-icons/fa";
import { useLocation } from "react-router";
import { Badge } from "reactstrap";

function SideBar({ user, theme }) {
  // if (user.obj?.role !== 'ADMIN') return (<></>)
  const [toggled, setToggled] = useState(false);
  const dispatch = useDispatch();
  const menu = [
    {
      "label": "Dashboard",
      "role": ["ADMIN"],
      "icon": <FaChartBar style={{ color: "#FF671D", fontSize: 20 }} fontSize="12px" />,
      "submenu": [
        {
          "label": "Analytics",
          "url": "/analytics/dashboard",
          "icon": "",
          "role": ["ADMIN"]
        },
        {
          "label": "User Actions",
          "url": "/analytics/user-actions",
          "icon": "",
          "role": ["ADMIN"]
        },
        {
          "label": "Pending Applications",
          "url": "/pending-applications",
          "icon": "",
          "role": ["ADMIN"],
          "suffix": <Badge title="New" color="success">New</Badge>
        }
      ]
    },
    {
      "label": "Users - Vendors",
      "icon": <FaUserFriends style={{ color: "#FF671D", fontSize: 20 }} fontSize="12px" />,
      "role": ["ADMIN"],
      "submenu": [
        {
          "label": "All Users",
          "url": "/internal/users",
          "icon": "",
          "role": ["ADMIN"]
        },
        {
          "label": "Create new user",
          "url": "/internal/register",
          "icon": "",
          "role": ["ADMIN"]
        },
        {
          "label": "All Exhibitors",
          "url": "/internal/exhibitors",
          "icon": "",
          "role": ["ADMIN"]
        }
      ]
    },
    {
      "label": "Events",
      "role": ["ADMIN"],
      "icon": <FaCalendarAlt style={{ color: "#FF671D", fontSize: 20 }} fontSize="12px" />,
      "submenu": [
        {
          "label": "Management",
          "url": "/internal/event",
          "icon": "",
          "role": ["ADMIN"]
        },
        {
          "label": "Add new event",
          "url": "/internal/event?modal=addEvent&modalSize=xl",
          "icon": "",
          "role": ["ADMIN"]
        }
      ]
    },
    {
      "label": "Booking",
      "role": ["ADMIN"],
      "icon": <FaShoppingCart style={{ color: "#FF671D", fontSize: 20 }} fontSize="12px" />,
      "submenu": [
        {
          "label": "Shopping Carts",
          "url": "/bookings/shopping-carts",
          "icon": "",
          "role": ["ADMIN"]
        },
        {
          "label": "Abandoned Carts",
          "url": "/bookings/abandoned-carts",
          "icon": "",
          "role": ["ADMIN"]
        },
        {
          "label": "Bookings",
          "url": "/bookings/management",
          "icon": "",
          "role": ["ADMIN"]
        },
        {
          "label": "Payments",
          "url": "/bookings/payments",
          "icon": "",
          "role": ["ADMIN"]
        },
        {
          "label": "SFA Submissions",
          "url": "/bookings/sfa",
          "icon": "",
          "role": ["ADMIN"]
        },
        {
          "label": "Promotions",
          "url": "/internal/promotions",
          "icon": "",
          "role": ["ADMIN"]
        }
      ]
    },
    {
      "label": "Lucky Draw",
      "role": ["ADMIN", "OPERATION"],
      "icon": <FaGamepad style={{ color: "#FF671D", fontSize: 20 }} fontSize="12px" />,
      "submenu": [
        {
          "label": "Entries",
          "url": "/winners",
          "icon": "",
          "role": ["ADMIN"]
        },
        {
          "label": "Lucky Draw Page",
          "url": `https://winner.qooee.com/?p=${user.obj?.auth?.jwt}`,
          "icon": "",
          "role": ["ADMIN", "OPERATION"]
        }
      ]
    },
    {
      "label": "Others",
      "role": ["ADMIN"],
      "icon": <FaCogs style={{ color: "#FF671D", fontSize: 20 }} fontSize="12px" />,
      "submenu": [
        {
          "label": "Connect XERO",
          "url": `${process.env.REACT_APP_API_HOST_SHORT}/v1/accounting/xero/connect`,
          "icon": "",
          "role": ["ADMIN"]
        },
        {
          "label": "Xero Invoices",
          "url": "",
          "icon": "",
          "role": ["ADMIN"]
        },
        {
          "label": "Contest",
          "url": "/game-results",
          "icon": "",
          "role": ["ADMIN"]
        },
        {
          "label": "Dynamic reports",
          "url": "/others/reports",
          "icon": "",
          "role": ["ADMIN"]
        },
        {
          "label": "EDM",
          "url": "/edm",
          "icon": "",
          "role": ["ADMIN"]
        }
      ]
    }
  ]

  const location = useLocation()
  const pathname = location.pathname;
  console.log(location)
  return (
    <div className="d-flex flex-column" style={{ borderRight: "solid 1px #efefef", background: "rgb(249, 249, 249, 0.7)", zIndex: 999 }}>
      <div className="d-flex flex-row p-4" style={{ cursor: "pointer", justifyContent: "space-between", alignItems: "center" }}>
        <FaBars onClick={() => setToggled(!toggled)} style={{  fontSize: 20 }} fontSize="12px" />
        {!toggled && <svg width="148" height="37" viewBox="0 0 148 37" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_4082_29773)">
          <path opacity="0.986" fill-rule="evenodd" clip-rule="evenodd" d="M17.4367 0.0833414C21.9995 -0.251103 26.0367 0.998897 29.5494 3.83334C33.5646 7.41334 35.2452 11.8672 34.5916 17.1945C34.2587 19.1706 33.5545 21.0039 32.479 22.6945C33.768 24.8389 35.0638 26.9778 36.3663 29.1111C36.3516 29.1811 36.3139 29.2367 36.2536 29.2778C35.2581 29.8611 34.2632 30.4444 33.2677 31.0278C32.1505 29.2317 31.0328 27.435 29.9156 25.6389C28.5933 26.8233 27.044 27.5545 25.2677 27.8333C20.7421 27.8706 16.2159 27.8706 11.6902 27.8333C9.50658 27.3839 8.12629 26.0967 7.54939 23.9722C7.14883 18.6378 8.74545 13.99 12.3381 10.0278C13.7015 8.62779 15.2694 7.50723 17.0424 6.66667C19.9646 5.55945 22.3026 6.27279 24.0564 8.80556C26.0936 12.2311 28.1595 15.6389 30.2536 19.0278C31.5657 15.6933 31.3218 12.4711 29.5212 9.36112C27.4756 6.27223 24.5933 4.42945 20.8733 3.83334C15.8097 3.25334 11.4812 4.72556 7.88742 8.25001C4.09813 12.4011 2.89615 17.1972 4.28179 22.6389C5.64404 26.8517 8.3201 29.9539 12.31 31.9444C16.1911 33.6644 20.1347 33.8128 24.1409 32.3889C24.7178 32.16 25.2812 31.9006 25.8311 31.6111C25.8953 31.5694 25.9516 31.5789 26.0001 31.6389C26.5398 32.6478 27.0936 33.6478 27.6621 34.6389C27.697 34.6917 27.6874 34.7378 27.6339 34.7778C23.2215 37.0483 18.6018 37.5672 13.7747 36.3333C7.66432 34.5117 3.4486 30.6689 1.12686 24.8056C-0.695282 19.4606 -0.244578 14.3122 2.47897 9.36112C5.39503 4.54445 9.64855 1.55334 15.2395 0.388897C15.977 0.263341 16.7094 0.161675 17.4367 0.0833414ZM19.0142 9.80556C19.0705 9.8039 19.1077 9.83167 19.1269 9.8889C18.275 15.3961 15.6362 19.85 11.2114 23.25C10.9702 18.7917 12.4632 15.0045 15.6902 11.8889C16.6739 10.9967 17.7821 10.3022 19.0142 9.80556ZM22.2254 12.9722C24.0964 16.0689 25.9838 19.1611 27.8874 22.25C27.9426 22.32 27.9618 22.3939 27.9438 22.4722C27.1077 23.3339 26.1032 23.9172 24.9297 24.2222C21.8497 24.2595 18.7702 24.2595 15.6902 24.2222C18.9364 21.0906 21.1145 17.3406 22.2254 12.9722Z" fill="#FB6719"/>
          <path opacity="0.984" fill-rule="evenodd" clip-rule="evenodd" d="M51.1269 8.3056C54.0058 8.13782 56.3816 9.11949 58.2537 11.25C58.2818 10.4167 58.2914 9.58338 58.2818 8.75004C59.5776 8.75004 60.8734 8.75004 62.1692 8.75004C62.1692 17.2873 62.1692 25.8239 62.1692 34.3612C60.8734 34.3612 59.5776 34.3612 58.2818 34.3612C58.2914 31.5095 58.2818 28.6573 58.2537 25.8056C56.5675 27.7962 54.3985 28.7778 51.7466 28.75C47.5748 28.5878 44.6171 26.6434 42.8734 22.9167C41.6773 19.8117 41.7523 16.7378 43.0987 13.6945C44.7866 10.4684 47.4627 8.67227 51.1269 8.3056ZM51.803 11.8612C54.9911 11.7412 57.1224 13.1489 58.1973 16.0834C58.7128 17.8362 58.6751 19.5773 58.0847 21.3056C56.6452 24.4556 54.1945 25.6867 50.7326 25C48.4379 24.3484 46.9449 22.895 46.2537 20.6389C45.6204 18.3112 45.9962 16.1628 47.3804 14.1945C48.5258 12.8106 50.0002 12.0328 51.803 11.8612Z" fill="black"/>
          <path opacity="0.986" fill-rule="evenodd" clip-rule="evenodd" d="M73.5501 8.30561C77.0797 8.15339 79.9997 9.37561 82.3107 11.9723C84.5338 14.8601 85.1535 18.0639 84.1698 21.5834C82.9084 25.2351 80.3822 27.5406 76.5923 28.5001C72.6109 29.2717 69.2216 28.2251 66.4233 25.3612C63.9501 22.3612 63.2554 18.9906 64.3388 15.2501C65.6121 11.8278 68.025 9.61506 71.5783 8.61117C72.2374 8.46784 72.8949 8.36561 73.5501 8.30561ZM73.3811 11.9723C76.8718 11.7523 79.2098 13.2523 80.3952 16.4723C80.967 18.6351 80.6481 20.6534 79.4374 22.5278C77.9084 24.4817 75.8898 25.3245 73.3811 25.0556C70.3011 24.5184 68.4701 22.7317 67.8881 19.6945C67.4876 17.1751 68.2295 15.0545 70.1135 13.3334C71.0797 12.5884 72.1692 12.1345 73.3811 11.9723Z" fill="black"/>
          <path opacity="0.986" fill-rule="evenodd" clip-rule="evenodd" d="M95.4093 8.30571C99.4877 8.16349 102.671 9.73793 104.959 13.0279C106.524 15.6818 106.899 18.4968 106.085 21.4724C104.967 24.9463 102.647 27.2329 99.1277 28.3335C95.2961 29.3285 91.8967 28.5507 88.9305 26.0002C86.1384 23.164 85.171 19.8029 86.0291 15.9168C87.1868 12.034 89.7502 9.58071 93.7192 8.55571C94.2871 8.44793 94.8505 8.3646 95.4093 8.30571ZM95.2403 11.9724C98.7113 11.7413 101.049 13.2224 102.254 16.4168C102.87 18.6474 102.532 20.7213 101.24 22.639C99.4189 24.7746 97.1186 25.5246 94.3389 24.889C91.6939 24.0352 90.1631 22.2485 89.7474 19.5279C89.4099 17.1368 90.1141 15.109 91.8601 13.4446C92.8465 12.634 93.9733 12.1429 95.2403 11.9724Z" fill="black"/>
          <path opacity="0.986" fill-rule="evenodd" clip-rule="evenodd" d="M117.21 8.30556C120.47 8.12612 123.146 9.25556 125.239 11.6944C126.599 13.5444 127.266 15.6183 127.239 17.9167C127.203 18.8261 127.137 19.7333 127.041 20.6389C121.947 20.6944 116.848 20.7128 111.746 20.6944C112.544 23.2494 114.29 24.7217 116.985 25.1111C119.158 25.4089 121.111 24.9089 122.844 23.6111C123.098 23.3611 123.351 23.1111 123.605 22.8611C124.401 23.7022 125.218 24.5261 126.056 25.3333C124.307 27.2789 122.11 28.39 119.464 28.6667C115.855 29.0672 112.785 28.0022 110.253 25.4722C108.125 23.0256 107.28 20.1922 107.718 16.9722C108.433 12.8228 110.809 10.0728 114.844 8.72223C115.629 8.51612 116.418 8.37723 117.21 8.30556ZM116.929 11.8056C118.07 11.7033 119.177 11.8422 120.253 12.2222C122.185 13.1572 123.274 14.685 123.52 16.8056C119.52 16.815 115.52 16.8056 111.52 16.7778C112.195 13.9183 113.998 12.2606 116.929 11.8056Z" fill="black"/>
          <path opacity="0.986" fill-rule="evenodd" clip-rule="evenodd" d="M137.945 8.30542C141.493 8.12486 144.301 9.43931 146.367 12.2499C147.458 13.9715 147.994 15.8515 147.973 17.8888C147.937 18.8276 147.861 19.7626 147.747 20.6943C142.658 20.6943 137.569 20.6943 132.48 20.6943C133.08 22.8482 134.47 24.2465 136.649 24.8888C138.796 25.4493 140.843 25.2088 142.79 24.1665C143.362 23.8093 143.869 23.3743 144.311 22.861C145.125 23.6921 145.952 24.516 146.79 25.3332C144.666 27.616 142.018 28.7549 138.846 28.7499C134.758 28.6938 131.669 26.9526 129.578 23.5276C128.398 21.2193 128.06 18.7938 128.564 16.2499C129.66 11.9471 132.411 9.34542 136.818 8.44431C137.203 8.40375 137.578 8.35764 137.945 8.30542ZM137.663 11.8054C141.346 11.5504 143.543 13.1982 144.254 16.7499C140.254 16.8149 136.254 16.8238 132.254 16.7776C132.903 13.8938 134.706 12.2365 137.663 11.8054Z" fill="black"/>
          </g>
          <defs>
          <clipPath id="clip0_4082_29773">
          <rect width="148" height="37" fill="white"/>
          </clipPath>
          </defs>
        </svg>}
      </div>

      <Sidebar collapsed={toggled}>
        <Menu
          menuItemStyles={{
            button: {
              // the active class will be added automatically by react router
              // so we can use it to style the active menu item
              [`&.active`]: {
                backgroundColor: '#13395e',
                color: '#b6c8d9',
              },
            },
          }}
        >
          {menu.filter(s => s.role.includes(user?.obj?.role)).map(s => {
            const isActive = s.submenu.find(m => m.url === pathname)
            return (
              <SubMenu active={isActive} open={isActive} style={{ fontWeight: "600"}} label={s.label} icon={s.icon}>
                {s.submenu.filter(m => m.role.includes(user?.obj?.role)).map(m => (
                  <MenuItem suffix={m.suffix || (<></>)} style={{ background: pathname === m.url ? "#ffe4d7" : "" }} component={<Link to={m.url} />}>{m.label}</MenuItem>
                ))}
              </SubMenu>
            )
          })}
          <MenuItem style={{ fontWeight: "600"}} icon={<FaArrowAltCircleRight style={{ color: "#FF671D", fontSize: 20 }} fontSize="12px" />} component={<div onClick={() => dispatch(logout())} />}>Log out</MenuItem>
        </Menu>
        <div style={{ display: "flex", justifyContent: "center", paddingBottom: "20px", marginTop: 40 }}>
          <div className="css-49v479">
            <div style={{ marginBottom: "12px" }}>
              { user.obj?.profile_photo
                ? <img src={user.obj?.profile_photo} width={40} height={40} alt="" style={{ borderRadius: 20 }} />
                : <svg width="37" height="38" viewBox="0 0 37 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path opacity="0.986" fill-rule="evenodd" clip-rule="evenodd" d="M17.4367 0.583341C21.9995 0.248897 26.0367 1.4989 29.5494 4.33334C33.5646 7.91334 35.2452 12.3672 34.5916 17.6945C34.2587 19.6706 33.5545 21.5039 32.479 23.1945C33.768 25.3389 35.0638 27.4778 36.3663 29.6111C36.3516 29.6811 36.3139 29.7367 36.2536 29.7778C35.2581 30.3611 34.2632 30.9444 33.2677 31.5278C32.1505 29.7317 31.0328 27.935 29.9156 26.1389C28.5933 27.3233 27.044 28.0545 25.2677 28.3333C20.7421 28.3706 16.2159 28.3706 11.6902 28.3333C9.50658 27.8839 8.12629 26.5967 7.54939 24.4722C7.14883 19.1378 8.74545 14.49 12.3381 10.5278C13.7015 9.12779 15.2694 8.00723 17.0424 7.16667C19.9646 6.05945 22.3026 6.77279 24.0564 9.30556C26.0936 12.7311 28.1595 16.1389 30.2536 19.5278C31.5657 16.1933 31.3218 12.9711 29.5212 9.86112C27.4756 6.77223 24.5933 4.92945 20.8733 4.33334C15.8097 3.75334 11.4812 5.22556 7.88742 8.75001C4.09813 12.9011 2.89615 17.6972 4.28179 23.1389C5.64404 27.3517 8.3201 30.4539 12.31 32.4444C16.1911 34.1644 20.1347 34.3128 24.1409 32.8889C24.7178 32.66 25.2812 32.4006 25.8311 32.1111C25.8953 32.0694 25.9516 32.0789 26.0001 32.1389C26.5398 33.1478 27.0936 34.1478 27.6621 35.1389C27.697 35.1917 27.6874 35.2378 27.6339 35.2778C23.2215 37.5483 18.6018 38.0672 13.7747 36.8333C7.66432 35.0117 3.4486 31.1689 1.12686 25.3056C-0.695282 19.9606 -0.244578 14.8122 2.47897 9.86112C5.39503 5.04445 9.64855 2.05334 15.2395 0.888897C15.977 0.763341 16.7094 0.661675 17.4367 0.583341ZM19.0142 10.3056C19.0705 10.3039 19.1077 10.3317 19.1269 10.3889C18.275 15.8961 15.6362 20.35 11.2114 23.75C10.9702 19.2917 12.4632 15.5045 15.6902 12.3889C16.6739 11.4967 17.7821 10.8022 19.0142 10.3056ZM22.2254 13.4722C24.0964 16.5689 25.9838 19.6611 27.8874 22.75C27.9426 22.82 27.9618 22.8939 27.9438 22.9722C27.1077 23.8339 26.1032 24.4172 24.9297 24.7222C21.8497 24.7595 18.7702 24.7595 15.6902 24.7222C18.9364 21.5906 21.1145 17.8406 22.2254 13.4722Z" fill="#FB6719"/>
                </svg>                
              }
            </div>
            {!toggled && <div>
              <p className="css-1t8vr00" style={{ fontWeight: "600" }}>{user.obj?.first_name} {user.obj?.last_name}</p>
              <Link to="/profile">
                <p className="css-idbnva" style={{ letterSpacing: "1px", opacity: "0.7" }}>Profile setting</p>
              </Link>
              <div style={{ marginTop: "16px" }}>
                  <p className="css-qnnkq7" style={{ color: "#FF671D", fontWeight: "600" }}>{user.obj?.role}</p>
              </div>
            </div>}
          </div>
        </div>

      </Sidebar>
    </div>
  )
}

export default connect(state => ({ user: state.user }))(SideBar)