export const USER_STATUS = {
  DELETED: -1,
  INACTIVE: 0,
  ACTIVE: 1
};

// TODO: get all event API
export function APIReplParams(path, params = {}) {
  const url = Object.entries(params).reduce((p, [key, value]) => {
    return p?.replace(`:${key}`, value);
  }, path);
  if (/:[a-zA-Z]+/.test(url))
    throw new Error(`Lack parameter(s) inside API URL: ${path}`);
  return url;
}
export function APIAuthAPI(user, ApiName, params = undefined) {
  let apiStr = (APIInternal)[ApiName]; //user.obj.role === "USER" 
  // if (typeof apiStr === "undefined") {
  //   throw new Error(`API ${ApiName} doesn't exist!`);
  // }
  if (typeof params === "object") apiStr = APIReplParams(apiStr, params);
  return apiStr;
}
export const APIInternal = {
  GET_ALL_EXHIBITOR: "v1/e/a/get-all",
  GET_ONE_EXHIBITOR: "v1/e/a/get-exh-by-id/:id",
  UPDATE_EXHIBITOR_STATUS: "v1/e/a/status/:id",
  UPDATE_EXHIBITOR_CREDIT: "v1/e/a/credit-rating/:id",
  MODIFY_USER_PROFILE: "v1/a/u/:id/info",
  GET_ALL_EVENT: "v1/evt/a/get-all",
  GET_ONE_EVENT: "v1/evt/a/info/:id",
  UPDATE_EVENT: "v1/evt/a/update-event/:id",
  ADD_EVENT: "v1/evt/a/add-event",
  BOOTH_LAYOUT: "v1/evt/a/booth-layouts/:eventId",
  BOOTH_TYPES_ALL: "v1/b/a/all-booth-types",
  GET_BOOTH_TYPE_BY_SLUG: "v1/b/a/booth-type-by-slug/:slug",
  BOOTH_ADD_TYPE: "v1/b/a/event/:eventId/booth-type",
  BOOTH_EDIT_TYPE: "v1/b/a/event/:eventId/booth-type/:id",
  POST_UPLOAD_BOOTH_SPEC: "/v1/b/a/event/:eventId/booths",
  POST_UPLOAD_BOOTH_PRICING: "/v1/b/a/event/:eventId/pricing",
  GET_ALL_PRICING: "/v1/b/a/event/:eventId/pricing",
  GET_ALL_BOOTHS: "/v1/b/a/event/:eventId/booths",
  DEL_ALL_BOOTHS: "/v1/b/a/event/:eventId/booths",
  DEL_ALL_PRICINGS: "/v1/b/a/event/:eventId/pricing",
  DEL_BOOTH_TYPE: "v1/b/a/booth-type/:id",
  GET_ALL_ORDER: "v1/booking/a/orders",
  GET_ALL_CARTS: "v1/booking/a/carts/:eventId",
  GET_ALL_ABANDONED_CARTS: "v1/booking/a/abandoned-carts/:eventId",
  QUERY_EWALLET: "v1/u/a/user-credit/:id",
  APPROVE_BOOKING: "v1/booking/a/approve-booking/:id",
  QUERY_INTENT: "v1/booking/a/intent-data/:id",
  CONFIRM_CART: "v1/booking/a/confirm-cart",
  ABANDON_CART: "v1/booking/a/reject-cart",
  GENERATE_BOOKING: "v1/booking/a/place-order",
  GENERATE_CART: "v1/booking/a/basket",
  EXCUTE_REPORT: "v1/o/a/reports/:reportId",
  GET_ALL_REPORT: "v1/o/a/all-reports",
  GET_ALL_PAYMENTS: "v1/booking/a/payments",
  GET_ALL_SFA_SUBMISSIONS: "v1/e/a/foodstall-handler-registration",
  SEND_SFA_REMINDER: "v1/e/a/send-sfa-reminder",

  //Reports
  GET_REPORTS_TIMELINE_STATS: "v1/reports/timeline-stats",
  GET_EXHIBITOR_REG_COUNT: "v1/reports/exhibitor-reg",
  GET_REPORTS_OVERVIEW: "v1/reports/overview",
  GET_REPORTS_EVENT_FINALIZE: "v1/reports/event-finalize",
  GET_REPORTS_EVENT_LEADERBOARD: "v1/reports/event-leaderboard",

  //Notifications
  GET_NOTIFICATION_GET_ACTIVE_USERS: "v1/a/u/active-users",
  USER_PROFILE: "v1/u/me",
  CHANGE_PASS: "v1/u/change-password",
  LOGIN: "v1/u/i/login",
  VERIFY_NEW_DEVICE: "v1/u/verify-new-device",
};
export const APIExternal = {
  GET_ALL_EXHIBITOR: "v1/e/get-all-exhibitors",
  GET_ONE_EXHIBITOR: "v1/e/get-exhibitor-by-id/:id",
  CREATE_EXHIBITOR: "v1/e/create-exhibitor",
  QUERY_USER: "v1/u/info/:email",
  UPDATE_EXHIBITOR: "v1/e/update-exhibitor/:id",
  GET_EXHIBITOR_MEMBERS: "v1/e/exhibitor-member/:id",
  POST_TAG_MEMBER_TO_EXHIBITOR: "v1/e/add-member-to-exhibitor/:id",
  DEL_REMOVE_MEMBER_FROM_EXHIBITOR: "/v1/e/remove-member/:exhibitorId/:userId",
  GET_ALL_EVENT: "v1/evt/get-all",
  GET_ONE_EVENT: "/v1/evt/info/:id",
  GET_ALL_BOOTHS: "/v1/b/event/:eventId/exhibitor/:exhId/booths-available",
  BOOTH_TYPES_ALL: "v1/b/event/:eventId/all-booth-types",
  GET_ALL_CART: "v1/booking/exhibitor/:exhibitorId/basket",
  ADD_TO_CART: "v1/booking/event/:eventId/exhibitor/:exhibitorId/basket",
  ORDER_PLACE_PUT:
    "v1/booking/event/:eventId/exhibitor/:exhibitorId/place-order",
  GET_ALL_ORDER: "v1/booking/exhibitor/:exhibitorId/orders"
};
export const APIMisc = {
  POST_UPLOAD: "v1/r/upload",
  GET_ALL_EXHIBITOR_STATUS: "v1/e/all-exhibitor-status"
};
