import React from "react";
import PropTypes from "prop-types";
import update from "react-addons-update";
import { axiosApi } from "../utils/api";
import { APIMisc } from "../utils/constant";

async function pureUploadAdapter(file) {
  const form = new FormData();
  form.append("file", file);
  const config = {
    headers: {
      "content-type": "multipart/form-data"
    }
  };
  const {
    data: { resource }
  } = await axiosApi.auth().post(APIMisc.POST_UPLOAD, form, config);
  if (typeof resource === "object" && resource !== null) return resource;
  else throw new Error("Something went wrong");
}

class FileUploadHandler extends React.Component {
  /**
   *
   * @type {HTMLElement}
   * @private
   */
  $el = null;
  /**
   *
   * @param file
   * @returns {*}
   * @private
   */
  _onUploadCompleted = file => file;
  static propTypes = {
    value: PropTypes.array.isRequired,
    onChange: PropTypes.func,
    accept: PropTypes.string,
    adapter: PropTypes.func,
    multiple: PropTypes.bool
  };
  static defaultProps = {
    adapter: pureUploadAdapter,
    multiple: false
  };

  state = {
    value: []
  };
  componentDidMount() {
    this._updateState();
  }

  getSnapshotBeforeUpdate(prevProps, prevState) {
    return {
      valueChange: prevProps?.value?.length !== this.props?.value?.length
    };
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (snapshot.valueChange) this._updateState();
  }

  /**
   *
   * @private
   */
  _updateState = () =>
    Array.isArray(this.props.value) &&
    this.setState({
      value: this.props.value.map(value => ({
        value,
        state: "uploaded"
      }))
    });

  /**
   *
   * @private
   */
  _onChange = () => {
    // console.log(this.state.value.map(v => v.value));
    this.props.onChange({
      target: { value: this.state.value.map(v => v.value) }
    });
  };

  /**
   *
   * @param target
   * @private
   */
  _inputOnChange = ({ target }) => {
    const self = this,
      isMultiple = self.props.multiple;
    if (target?.files) {
      // temporarily add 'uploading' files to state
      const filesAdded = Array.from(target.files).map(file => ({
          value: "",
          state: "uploading",
          promise: new Promise((resolve, reject) => {
            self.props.adapter(file).then(async resource => {
              resolve({
                value: await self._onUploadCompleted(
                  resource.location ? resource.location : resource
                ),
                state: "uploaded"
              });
            });
          })
        })),
        promises = filesAdded.map(f => f.promise);
      const lastIndex = self.state.value.length;
      self.setState(
        state => {
          return update(state, {
            value: {
              [isMultiple ? "$push" : "$set"]: filesAdded
            }
          });
        },
        async () => {
          const filesUploaded = await Promise.all(promises);
          // ... then remove them and add exactly uploaded files again
          self.setState(
            state =>
              update(state, {
                value: isMultiple
                  ? {
                      $splice: [
                        [lastIndex, filesUploaded.length, ...filesUploaded]
                      ]
                    }
                  : { $set: filesUploaded }
              }),
            () => {
              self._onChange();
            }
          );
        }
      );
    }
  };

  /**
   *
   * @returns {*}
   * @private
   */
  _renderFileInput = () => {
    const self = this,
      inputProps = {};
    if (self.props.accept) inputProps.accept = self.props.accept;
    return (
      <input
        ref={el => {
          self.$el = el;
        }}
        hidden
        type="file"
        onChange={self._inputOnChange}
        {...inputProps}
      />
    );
  };

  /**
   *
   * @private
   */
  _openFileDialog = () => this.$el?.click();

  /**
   *
   * @param index
   * @param withAPICall
   * @returns {Promise<void>}
   * @private
   */
  _deleteFile = async (index, withAPICall = null) => {
    const setFileState = (fileState, remove = false) =>
      new Promise((resolve, reject) => {
        this.setState(state => {
          let updateSpec;
          if (index === 0 && state.value.length === 1)
            updateSpec = { $set: [] };
          else
            updateSpec = {
              $splice: [
                [
                  index,
                  1,
                  remove
                    ? false
                    : {
                        ...state.value[index],
                        state: fileState
                      }
                ].filter(Boolean)
              ]
            };
          return update(state, {
            mutating: { $set: true },
            value: updateSpec
          });
        }, resolve);
      });
    await setFileState("deleting");
    if (typeof withAPICall === "function") await withAPICall();
    await setFileState(null, true);
    this._onChange();
  };

  /**
   *
   * @param onUploadCompleted
   * @param children
   * @returns {*}
   * @private
   */
  _handler = ({ onUploadCompleted = v => v, children }) => {
    React.useEffect(() => {
      this._onUploadCompleted = onUploadCompleted;
    });
    return children;
  };

  get fileUploadProps() {
    const self = this;
    return {
      openFileDialog: self._openFileDialog.bind(self),
      deleteFile: self._deleteFile.bind(self),
      FileUploadHandler: self._handler.bind(self)
    };
  }

  render() {
    // const { value, children } = this.props;
    return (
      <>
        {this.props.children({
          value: this.state.value,
          fileUpload: this.fileUploadProps
        })}
        {this._renderFileInput()}
      </>
    );
  }
}

const withFileUpload = uploadPropFn => Component =>
  function({ value, onChange, ...props }) {
    let _uploadPropFn = Object.assign(
      { accept: "*", multiple: false, adapter: pureUploadAdapter },
      typeof uploadPropFn === "object" ? uploadPropFn : uploadPropFn(props)
    );

    return (
      <FileUploadHandler
        value={value}
        onChange={onChange}
        accept={_uploadPropFn.accept}
        multiple={_uploadPropFn.multiple}
        adapter={_uploadPropFn.adapter}
      >
        {({ value: _value, fileUpload }) => (
          <Component value={_value} fileUpload={fileUpload} {...props} />
        )}
      </FileUploadHandler>
    );
  };

export default withFileUpload;
export { FileUploadHandler, pureUploadAdapter };
